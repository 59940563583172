import { priceToNumber } from './price';
import { isNumber, isOn } from './utils';

import type { TypeMetaDatas, TypeProduct, TypeProductStock } from '@/types';
import type { WoosbParameters } from '../graphql/transformers/product/woosb';

/**
 * Check if the product is simple
 * @param  {TypeProduct} product Product
 * @return {boolean}             True if it's simple or false if not
 */
export const isSimple = (product: TypeProduct) =>
  product === 'simple' || product === 'SIMPLE' || product === 'SimpleProduct';

/**
 * Check if the product is variable
 * @param  {TypeProduct} product Product
 * @return {boolean}             True if it's variable or false if not
 */
export const isVariable = (product: TypeProduct) =>
  product === 'variable' ||
  product === 'VARIABLE' ||
  product === 'VariableProduct';

/**
 * Check if the product is bundle
 * @param  {TypeProduct} product Product
 * @return {boolean}             True if it's bundle or false if not
 */
export const isBundle = (product: TypeProduct) =>
  product === 'woosb' || product === 'WoosbProduct';

const allAreVarations = (
  variations: { node: { databaseId: number } }[],
  woosbIds: WoosbParameters['woosbIds'],
) =>
  variations?.some((variation) =>
    woosbIds?.some((woosbId) => woosbId.id === variation.node.databaseId),
  ) ?? false;

/**
 * Check if the product is bundle without variables
 *
 * The product can contain variations, bundles and simple products
 *
 * @param  {TypeProduct} product              Product
 * @param  {Object}      options              Options
 * @param  {Array}       options.bundledItems Bundled items
 * @param  {Array}       options.woosbIds     Woosb ids
 * @param  {string}      options.stock        Stock
 * @return {boolean}             True if it's bundle without variables or false if not
 */
export const isBundleWithoutVariables = (
  product: TypeProduct,
  {
    bundledItems,
    woosbIds,
    stock,
  }: {
    bundledItems?:
      | {
          type: TypeProduct;
          variations: { edges: { node: { databaseId: number } }[] };
        }[]
      | undefined;
    woosbIds: WoosbParameters['woosbIds'];
    stock: TypeProductStock | undefined;
  },
) => {
  const safeBundledItems = bundledItems?.filter(Boolean);
  const variables = safeBundledItems?.filter((item) => isVariable(item.type));

  return (
    (isBundle(product) &&
      safeBundledItems?.every((item) => isSimple(item.type))) ||
    (isBundle(product) &&
      woosbIds?.length === 0 &&
      stock &&
      isInStock(stock)) ||
    (isBundle(product) &&
      variables?.every((variable) =>
        allAreVarations(variable.variations.edges, woosbIds),
      ))
  );
};

export const isBundleWithVariables = (
  product: TypeProduct,
  {
    bundledItems,
    woosbIds,
  }: {
    bundledItems?:
      | {
          type: TypeProduct;
          variations: { edges: { node: { databaseId: number } }[] };
        }[]
      | undefined;
    woosbIds: WoosbParameters['woosbIds'];
  },
) => {
  const safeBundledItems = bundledItems?.filter(Boolean);
  const variables = safeBundledItems?.filter((item) => isVariable(item.type));

  return (
    isBundle(product) &&
    !variables?.every((variable) =>
      allAreVarations(variable.variations.edges, woosbIds),
    )
  );
};

/**
 * Check if the product is bundle and is configurable
 * @param  {TypeProduct} product        Product
 * @param  {string}      isConfigurable Is configurable bundle
 * @return {boolean}                    True if it's bundle and is active or false if not
 */
export const isConfigurableBundle = (
  product: TypeProduct,
  isConfigurable: string | null | undefined,
) =>
  (product === 'woosb' || product === 'WoosbProduct') && isOn(isConfigurable);

/**
 * Check if the product is external
 * @param  {TypeProduct} product Product
 * @return {boolean}             True if it's external or false if not
 */
export const isExternal = (product: TypeProduct) =>
  product === 'external' ||
  product === 'EXTERNAL' ||
  product === 'ExternalProduct';

/**
 * Check if has stock
 * @param  {TypeProductStock} stock Stock
 * @return {boolean}                True if has stock or false if hasn't
 */
export const isInStock = (stock: TypeProductStock) =>
  stock === 'instock' || stock === 'IN_STOCK';

/**
 * Check if hasn't stock
 * @param  {TypeProductStock} stock Stock
 * @return {boolean}                True if hasn't stock or false if has
 */
export const isOutOfStock = (stock: TypeProductStock) =>
  stock === 'onbackorder' ||
  stock === 'ON_BACK_ORDER' ||
  stock === 'outofstock' ||
  stock === 'OUT_OF_STOCK';

/**
 * Check if is a mirakl product
 * @param {TypeMetaDatas} metaData Metadata of the product
 * @returns {boolean} True if it is a mirakl product
 */
export const isMirakl = (metaData: TypeMetaDatas): boolean =>
  metaData?.some(
    (data) => data.key === 'is_mirakl_product' && data.value === 'on',
  ) ?? false;

/**
 * Check if the product has disabled the stocks alers
 * @param {TypeMetaDatas} metaData Metadata of the product
 * @returns {boolean} True if the product has disabled the stocks alers
 */
export const hasDisableStockAlert = (metaData: TypeMetaDatas): boolean =>
  metaData?.some(
    (meta) => meta.key === 'disable_stock_alert' && meta.value === 'yes',
  ) ?? false;

/**
 * Check if the product has free shipping
 * @param shippingClasses Array of the shipping classes of the product
 * @param sellerBox Seller info about the product
 * @param price Price of the product
 * @returns True if has free shipping or false if not
 */
export const hasFreeShipping = (
  shippingClasses?: { node: { slug?: string } }[],
  sellerBox?: {
    name: string;
    slug: string;
    freeShippingAmount?: string | null;
    alwaysFree?: boolean | null;
  },
  price?: string | number,
): boolean =>
  (shippingClasses?.some((i) => i.node?.slug === 'free-shipping') ||
    (isNumber(sellerBox?.freeShippingAmount) &&
      price &&
      Number(sellerBox?.freeShippingAmount) <= priceToNumber(price)) ||
    sellerBox?.alwaysFree) ??
  false;

export const PRODUCT_TEMPLATES = {
  CUSTOM_TAG: 'custom-tag',
  BUNDLE_BOX_CUSTOMIZABLE: 'bundle-box-customizable',
  BUNDLE_CUSTOMIZABLE: 'bundle-customizable',
  FREE_STYLE: 'free-style',
} as const;
